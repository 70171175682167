



































































































































































import { defineComponent, ref, inject, computed } from '@vue/composition-api';
import { useUserActions, useUserState } from '@/store';
import axios from 'axios';
import Table from './TableView.vue';
import MongoDoc from './types';

export default defineComponent({
  name: 'MContactDefault',
  components: {
    Table
  },
  props: {
    value: {
      required: true,
      type: Object as () => MongoDoc
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    studentId: {
      type: String,
      default: ''
    },
    adkType: {
      type: String,
      default: ''
    },
    userType: {
      required: false,
      type: String,
      default: ''
    },
    adkIndex: {
      type: Number,
      default: 0
    }
  },
  setup(props: any, ctx: any) {
    const showInstructions = ref(true);
    const readonly = inject('readonly', false);
    const { user } = useUserState(['user']);
    const { updateProgramState } = useUserActions(['updateProgramState']);
    const studentPref = ref({
      contactMethod: [],
      contactPreferences: [],
      hideEmail: false,
      hidePhone: false,
      schedulingLink: ''
    });
    const isDataAvailable = ref(false);
    const isSaving = ref(false);
    const isFormShow = ref(false);
    const isPersistent = ref(false);
    const table = ref();
    const isValid = computed(() => {
      if (
        studentPref.value?.contactMethod?.length ||
        studentPref.value?.contactPreferences?.length ||
        studentPref.value?.schedulingLink
      ) {
        return true;
      }
      return false;
    });

    const adk_index = computed(() => {
      return props.adkIndex;
    });

    const programDoc: any = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    const adkIdValue: any = computed(() => {
      return programDoc?.value?.data?.adks[props.adkIndex]?.id;
    });

    const userTypeValue = computed({
      get: () => props.userType,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const programIdValue: any = computed(() => {
      if (userTypeValue.value === 'participant') {
        return programDoc?.value?.data?.program_id?.toString();
      }
      return programDoc?.value?.data._id?.toString();
    });

    const adk = ref(programDoc.value?.data?.adks?.find(a => a.name === props.adkType));

    const fieldIndex = ref(adk.value?.fields?.findIndex(f => f.name === 'm-contact-default'));

    const fieldValue = computed(() => {
      let field;
      field = adk.value?.fields?.find(f => f.name === 'm-contact-default').value;
      if (!field) {
        field = adk.value?.presetFields?.find(f => f.name === 'm-contact-default').value;
      }
      return field;
    });

    const studentPayload = computed({
      get: () => studentPref.value,
      set: newVal => {
        studentPref.value = newVal;
      }
    });

    if (fieldValue.value?.directoryPreferences) {
      studentPref.value = {
        ...fieldValue.value?.directoryPreferences[0]
      };
      isPersistent.value = false;
    } else if (!props.isMonitor) {
      isFormShow.value = true;
      isPersistent.value = true;
    }

    function openPrefDialog() {
      if (fieldValue?.value && fieldValue.value?.directoryPreferences) {
        if (Array.isArray(fieldValue.value?.directoryPreferences)) {
          studentPayload.value = fieldValue?.value?.directoryPreferences[0];
        } else {
          studentPayload.value = fieldValue?.value?.directoryPreferences;
        }
        isDataAvailable.value = true;
        isFormShow.value = true;
      }
      isFormShow.value = true;
      isPersistent.value = false;
    }

    async function saveData() {
      try {
        isSaving.value = true;
        const data = {
          public_id: programDoc.value?.data?._id.toString(),
          student_id: programDoc.value?.data._id.toString(),
          program_id: ctx.root._route.params.programId || programIdValue?.value,
          contact_method: studentPref.value.contactMethod,
          contact_preferences: studentPref.value.contactPreferences,
          hide_email: studentPref.value.hideEmail,
          hide_phone: studentPref.value.hidePhone,
          scheduling_link: studentPref.value.schedulingLink,
          adkId: adkIdValue.value
        };

        const res = await axios.post(`${process.env.VUE_APP_ADK_DIRECTORY_CREATE_USER}`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          isSaving.value = false;
          isFormShow.value = false;
          isPersistent.value = false;
          isDataAvailable.value = true;
          updateProgramState(res.data?.data?.document);
          ctx.emit('update-doc');
          table.value.getTableData();
        }
      } catch (error) {
        isSaving.value = false;
        isFormShow.value = false;
        isPersistent.value = false;
      }
    }

    function updateData() {
      isSaving.value = true;
      const data = {
        public_id: programDoc.value?.data?._id.toString(),
        student_id: programDoc.value?.data?._id.toString(),
        program_id: ctx.root._route.params.programId || programIdValue?.value,
        contact_method: studentPref.value.contactMethod,
        contact_preferences: studentPref.value.contactPreferences,
        hide_email: studentPref.value.hideEmail,
        hide_phone: studentPref.value.hidePhone,
        scheduling_link: studentPref.value.schedulingLink,
        adkId: adkIdValue.value
      };
      axios
        .patch(`${process.env.VUE_APP_ADK_DIRECTORY_CREATE_USER}`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        })
        .then(res => {
          isSaving.value = false;
          isFormShow.value = false;
          updateProgramState(res.data?.data?.document);
          ctx.emit('update-doc');
          table.value.getTableData();
        })
        .catch(error => {
          isSaving.value = false;
        });
    }
    return {
      showInstructions,
      studentPayload,
      studentPref,
      readonly,
      isDataAvailable,
      isSaving,
      saveData,
      updateData,
      isFormShow,
      isValid,
      table,
      openPrefDialog,
      isPersistent,
      programIdValue,
      adkIdValue
    };
  }
});
